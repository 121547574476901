import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import styles from './stock.module.css';
import Pagination from './pagination';
import { AppContext } from '../../Services/context';
import Medicine from './medicine_small';
import Image_canvas from './Image_canvas';
import Med_card from './med_card';
import Med_sticker from './med_sticker';
import { useTranslation } from 'react-i18next';

const Stock = () => {

    const {
        allStock,
        currentPage,
        setCurrentPage,
        setTotalPages,
        inputValue,
        setInputValue,
        textUpload,
        imageUpload,
        refreshStock,
        setRenderTarget,
        stockToRender,
        setStockToRender,
        isFetching,
        stockPerPage,
        setStockPerPage,
        listView,
        setListView,
        checkNationalStock,
        renderTarget,

    } = useContext(AppContext);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageCanvasOpen, setIsImageCanvasOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t } = useTranslation()



    const toggle_list_View = () => {
        setListView(true);
        handleViewChange(9)
    }
    const toggle_card_View = () => {
            handleViewChange(4)
        setListView(false);
    }

    const handleViewChange = (amount) => {
        const indexOfFirstItem = (currentPage - 1) * stockPerPage;
        const newCurrentPage = Math.floor(indexOfFirstItem / amount) + 1;
        const newTotalPages = Math.ceil(allStock.length / amount);
        const updatedPage = Math.min(newCurrentPage, newTotalPages);
        setTotalPages(Math.ceil(allStock.length / amount));
        setStockPerPage(amount);
        if (updatedPage <= 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(updatedPage);
        }
    }

    const toggleCanvas = (each) => {
        setIsModalOpen(!isModalOpen);
        setRenderTarget(each);
        setIsMenuOpen(false)
    };

    const toggleImageCanvas = () => {
        setIsMenuOpen(false)
        setIsImageCanvasOpen(true)
    }

    const textSearch = (e) => {
        e.preventDefault();

        if (inputValue === "") {
            return;
        } else {
            textUpload(inputValue);
            setIsMenuOpen(false)
        }

    };

    const fileInputRef = useRef(null);
    const openImagePicker = () => {
        fileInputRef.current.click();
    };
    const handelImageSelection = (e) => {
        e.preventDefault()
        const image = e.target.files[0]
        if (!image) {
            alert('Invalid file or you did not choose one!')
            return;
        }

        const fileExtension = image.name.split('.').pop().toLowerCase();

        if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
            alert('Please select .jpg or .png image')
        } else {
            setIsMenuOpen(false)
            imageUpload(image)
        }
        fileInputRef.current.value = ""; 
    }

    useEffect(() => {
        const indexOfLastImage = currentPage * stockPerPage;
        const indexOfFirstImage = indexOfLastImage - stockPerPage;
        const currentStock = allStock.slice(indexOfFirstImage, indexOfLastImage);
        setStockToRender(currentStock);
    }, [currentPage, allStock, stockPerPage])


    useEffect(() => {
        if (renderTarget) {
            checkNationalStock();
        }
    }, [renderTarget])

    return (

        <div className={styles.stockdiv}>

            <Medicine
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />

            <Image_canvas
                isImageCanvasOpen={isImageCanvasOpen}
                setIsImageCanvasOpen={setIsImageCanvasOpen}
            />
            <div className={styles.title}>
                <h1>{t('stock_title')}</h1>
            </div>

            <div className={styles.input_area}>
                <div className={styles.input_div}>
                    <input
                        type="text_input"
                        className={styles.input}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder={t('stock_typetosearch')}
                    />
                    <button onClick={textSearch} className={styles.btn} type="submit">{t('stock_textsearch')}</button>

                    <div className={styles.imgbtndiv}>
                        {<button onClick={() => { setIsMenuOpen(!isMenuOpen) }} className={styles.imgbtn} type="submit">{t('stock_imagesearch')}</button>}
                        {isMenuOpen && (
                            <div className={styles.menu}>
                                <div className={styles.menuItem} onClick={() => openImagePicker()}>
                                    {t('stock_upload')}
                                </div>
                                <div className={styles.menuItem} onClick={() => toggleImageCanvas()}>
                                    {t('stock_take')}
                                </div>
                            </div>
                        )}

                        {/* <button className={styles.imgbtn} onClick={openImagePicker}>Upload image</button>
                        <button className={styles.imgbtn} onClick={toggleImageCanvas}>take a photo</button> } */}
                    </div>
                    <button onClick={() => (refreshStock(), setIsMenuOpen(false))} className={styles.btn} type="submit">{t('stock_reset')}</button>
                    <input type="file" accept=".jpg,.jpeg,.png" style={{ display: 'none' }} ref={fileInputRef} onChange={handelImageSelection} />
                </div>
            </div>

            <div className={styles.indicator}>
                <p>{t('search_display')} {currentPage * stockPerPage - (stockPerPage - 1)}-{Math.min(currentPage * stockPerPage, allStock.length)}  / {allStock.length} </p>
                <button onClick={listView ? toggle_card_View : toggle_list_View} className={styles.switch}>{listView ? t('search_list') : t('search_card')}</button>
            </div>

            <>
                {listView &&
                    <div className={styles.list_indicator}>
                        <p className={styles.list_img}></p>
                        <p className={styles.list_product}>{t('search_product')}</p>
                        <p className={styles.list_index}>No.</p>
                        <p className={styles.list_position}>{t('search_shelf')}</p>
                        <p className={styles.list_manu}>{t('search_supplier')}</p>
                        <p className={styles.list_quantity}>{t('stock_quantity')}</p>
                        <p className={styles.list_price}>{t('search_price')}</p>
                    </div>

                }
                {isFetching
                    ?
                    <div className={styles.fetchingDiv}><h1>{t('search_fetch')}</h1></div>
                    :

                    <div className={listView ? styles.med_list : styles.med_card}>

                        {stockToRender.length > 0 ? (
                            stockToRender.map((each, index) => (
                                <>
                                    {!listView
                                        ?
                                        <Med_card
                                            toggleCanvas={toggleCanvas}
                                            each={each}
                                            index={index}
                                        />
                                        :
                                        <Med_sticker
                                            toggleCanvas={toggleCanvas}
                                            each={each}
                                            index={index}

                                        />
                                    }
                                </>

                            ))
                        ) : (
                            <div className={styles.no_stock}>
                                <h1>No stock available</h1>
                            </div>

                        )}
                    </div>
                }
            </>
            {stockToRender.length > 0
                ?
                <div className={`${styles.arrow} ${isFetching ? styles.hidden : null}`}>
                    <Pagination />
                </div>
                :
                null}
        </div>
    )
}

export default Stock;
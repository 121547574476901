import React from "react";
import styles from './medicine_small_alt.module.css'
import { useState, useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../Services/context";
import Phar_sto from "../stock/phar_sto";
import { useTranslation } from "react-i18next";


const Medicine_alt = ({ isModalOpen, setIsModalOpen }) => {

    const {t} = useTranslation()
    const { renderTarget, num_of_sto, num_of_phar } = useContext(AppContext);
    const [isPharOpen, setIsPharOpen] = useState(false);

    const triggerCheck = ()=>{
        setIsPharOpen(true);
    }

    const handleClose = (e) => {

        if (e.target.className.includes('overlay')) {
            setIsModalOpen(false);
        }
    };

    return (
        <>
            {isModalOpen && (
                <div className={styles.overlay} onClick={handleClose}>
                    <div className={styles.floating_window}>
                    <button className={styles.btnnnn} onClick={() => setIsModalOpen(false)}><img src='/clear.png' alt="Close" width='40' height='40' className={styles.icon_image} /></button>

                        <div className={styles.product_grid}>
                            <Phar_sto
                            isPharOpen={isPharOpen}
                            setIsPharOpen={setIsPharOpen}
                            />
                            <div className={styles.image_area}>
                                <img className={styles.img} src={renderTarget.med_url} alt={renderTarget.med_name} onClick={() => window.open(`${renderTarget.med_url}`, '_blank')} />
                            </div>
                            <div className={styles.details_area}>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_name')}</p> 
                                    <p className={styles.content}>{renderTarget.med_name}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_supplier')}</p> 
                                    <p className={styles.content}>{renderTarget.med_manufacturer}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_store_position')}</p> 
                                    <p className={styles.content}>{renderTarget.sto_place_in_store}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_stock_position')}</p> 
                                    <p className={styles.content}>{renderTarget.sto_place_in_stock}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_price')}</p>
                                    <p className={styles.content}>$ {renderTarget.sto_pri}</p> 
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_composition')}</p> 
                                    <p className={styles.content}>{renderTarget.med_composition}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_description')}</p> 
                                    <p className={styles.content}>{renderTarget.med_description}</p>
                                </div>
                            </div>
                            <div className={styles.quantity_area}>
                                <p onClick={triggerCheck} className={styles.chk_cross}>{num_of_sto}{t('search_units')}{num_of_phar}{t('search_stores')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Medicine_alt;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pwdinput_blocker__aCGn7 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}


.pwdinput_outer__5RmOJ {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-width: 270px;
    max-width: 70%;
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10000;
}

.pwdinput_inpt__IktdG {
    min-width: 100%;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 5px;
}

.pwdinput_btnn__7e\\+Qe {
    font-size: 16px;
    color: white;
    background-color: #718772;
    color:#f9f9f9;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 104px;
}

.pwdinput_btnn__7e\\+Qe:hover {
    background-color: #4c684c;
}

.pwdinput_btnn__7e\\+Qe.pwdinput_dis__Nrkbg {
    background-color: #bdc4bd;
    cursor: unset;
}

.pwdinput_msg__QX75R {
    margin-top: -5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/stock/pwdinput.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;;AAGA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,yCAAyC;IACzC,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".blocker {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 9999;\r\n}\r\n\r\n\r\n.outer {\r\n    background-color: white;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n    min-width: 270px;\r\n    max-width: 70%;\r\n    position: absolute;\r\n    margin: auto;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    z-index: 10000;\r\n}\r\n\r\n.inpt {\r\n    min-width: 100%;\r\n    background: white;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n    text-align: center;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.btnn {\r\n    font-size: 16px;\r\n    color: white;\r\n    background-color: #718772;\r\n    color:#f9f9f9;\r\n    border: none;\r\n    border-radius: 8px;\r\n    cursor: pointer;\r\n    margin-top: 5px;\r\n    margin-bottom: 5px;\r\n    width: 104px;\r\n}\r\n\r\n.btnn:hover {\r\n    background-color: #4c684c;\r\n}\r\n\r\n.btnn.dis {\r\n    background-color: #bdc4bd;\r\n    cursor: unset;\r\n}\r\n\r\n.msg {\r\n    margin-top: -5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blocker": `pwdinput_blocker__aCGn7`,
	"outer": `pwdinput_outer__5RmOJ`,
	"inpt": `pwdinput_inpt__IktdG`,
	"btnn": `pwdinput_btnn__7e+Qe`,
	"dis": `pwdinput_dis__Nrkbg`,
	"msg": `pwdinput_msg__QX75R`
};
export default ___CSS_LOADER_EXPORT___;

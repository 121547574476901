import React, { useContext } from 'react';
import styles from './mainSidebar.module.css';
import { AppContext } from '../../Services/context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const MainSidebar = () => {
    const {
        logged_in,
        pha_login,
        pwdInput,
        setPwdInput,
        currentcontent,
        changeContent,
        lockConsole,
        pharmacy,
        current_language,
        set_current_language
    } = useContext(AppContext);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const toggle_eng = () => {
        set_current_language('en');
        i18n.changeLanguage('en')

    };
    const toggle_maori = () => {
        set_current_language('ma');
        i18n.changeLanguage('ma')

    };
    const toggle_cn = () => {
        set_current_language('cn');
        i18n.changeLanguage('cn')

    };

    const toggle_jp = () => {
        set_current_language('jp');
        i18n.changeLanguage('jp')
    };

    const toggle_de = () => {
        set_current_language('de');
        i18n.changeLanguage('de')
    };

    const toggle_kr = () => {
        set_current_language('kr');
        i18n.changeLanguage('kr')
    }


    const handle_lock = ()=>{
        navigate('/')
        lockConsole();

    }
    if (!logged_in) {
        return (
            <div className={styles.container}>
                <img src='/frwgreen.png' alt="FRW Healthcare Logo" className={styles.logo_image} />
                <div className={styles.hint}>
                    <h1>{t('unauth')}</h1>
                </div>
                <div className={styles.lan}>
                <button className={`${styles.lan_btn} ${current_language === "en" ? styles.selected : null}`} onClick={toggle_eng}>English</button>
                <button className={`${styles.lan_btn} ${current_language === "ma" ? styles.selected : null}`} onClick={toggle_maori}>Māori</button>
                <button className={`${styles.lan_btn} ${current_language === "de" ? styles.selected : null}`} onClick={toggle_de}>Deutsch</button>
                <button className={`${styles.lan_btn} ${current_language === "cn" ? styles.selected : null}`} onClick={toggle_cn}>中文</button>
                <button className={`${styles.lan_btn} ${current_language === "jp" ? styles.selected : null}`} onClick={toggle_jp}>日本語</button>
                <button className={`${styles.lan_btn} ${current_language === "kr" ? styles.selected : null}`} onClick={toggle_kr}>한국어</button>
            </div>
            </div>
        )
    } else {
        return (
            <div className={styles.container}>
                <p className={`${styles.logo_image} ${currentcontent === "welcome" ? styles.active : null}`}
                    onClick={() => changeContent('welcome')}>
                    <img src='/frwgreen.png' alt="FRW Healthcare Logo" className={styles.logo_image} />
                </p>
                <div className={styles.hint}>
                    <h1>{pharmacy.pha_name}</h1>
                    <h1>{pharmacy.pha_region}</h1>
                </div>
                <p className={`${styles.p} ${currentcontent === "stock" ? styles.active : null}`}
                    onClick={() => changeContent('stock')}>
                    <img src='/all.png' alt="Inventory" width='20' height='20' className={`${styles.icon_image} ${styles.stock_icon}`} />{t('sidebar_inventory')}
                </p>
                <p className={`${styles.p} ${currentcontent === "search" ? styles.active : null}`}
                    onClick={() => changeContent('search')}>
                    <img src='/search.png' alt="Search" width='20' height='20' className={`${styles.icon_image} ${styles.search_icon}`} />{t('sidebar_search')}
                </p>
                <p className={`${styles.p} ${currentcontent === "stores" ? styles.active : null}`}
                    onClick={() => changeContent('stores')}>
                    <img src='/map.png' alt="Store Locator" width='20' height='20' className={`${styles.icon_image} ${styles.stores_icon}`} />{t('sidebar_store_locator')}
                </p>
                <p className={styles.lock}
                    onClick={() => handle_lock()}>
                    <img src='/end.png' alt="Sign Out" width='20' height='20' className={styles.icon_image} />{t('sidebar_signout')}
                </p>
                <div className={styles.lan}>
                <button className={`${styles.lan_btn} ${current_language === "en" ? styles.selected : null}`} onClick={toggle_eng}>English</button>
                <button className={`${styles.lan_btn} ${current_language === "ma" ? styles.selected : null}`} onClick={toggle_maori}>Māori</button>
                <button className={`${styles.lan_btn} ${current_language === "de" ? styles.selected : null}`} onClick={toggle_de}>Deutsch</button>
                <button className={`${styles.lan_btn} ${current_language === "cn" ? styles.selected : null}`} onClick={toggle_cn}>中文</button>
                <button className={`${styles.lan_btn} ${current_language === "jp" ? styles.selected : null}`} onClick={toggle_jp}>日本語</button>
                <button className={`${styles.lan_btn} ${current_language === "kr" ? styles.selected : null}`} onClick={toggle_kr}>한국어</button>
            </div>
            </div>
        );
        
        
    }
    ;
};
export default MainSidebar;
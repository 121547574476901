import React, { useContext } from "react";
import styles from './phar_sto.module.css'
import StoreLocator from "../stores/storelocator";
import { AppContext } from "../../Services/context";
import { useTranslation } from "react-i18next";


const Phar_sto = ({
    isPharOpen,
    setIsPharOpen
}) => {

    const { nationalSto, pharmacy } = useContext(AppContext);
    const {t} = useTranslation()

    return (
        <>
            {
                isPharOpen
                &&
                <div className={styles.blocker}>
                    <div className={styles.outer}>
                        <h4>
                            {t('nation_wide_stock_title')}
                        </h4>
                        <div className={styles.indi}>
                            <p>{t('nation_wide_stock_pharmacy')}</p>
                            <p>{t('nation_wide_stock_quantity')}</p>
                        </div>
                        {nationalSto.length > 0 ? (
                            nationalSto.map((each, index) => (
                                <div key={index} className={styles.each}>
                                    <p className={each.pha_name===pharmacy.pha_name?styles.same:null}>{each.pha_name}</p>
                                    <p>{each.sto_quan}</p>
                                </div>
                            ))
                        ) : (
                            <h4>
                                {t('nation_wide_no_stock')}
                            </h4>
                        )}

                        <button className={styles.btnnn} onClick={() => setIsPharOpen(false)}>{t('nation_wide_close')}</button>

                    </div>

                </div>}
        </>
    )
}

export default Phar_sto;
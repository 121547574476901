import React from "react";
import styles from './medicine_small.module.css'
import { useState, useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../Services/context";
import Pwdinput from "./pwdinput";
import Phar_sto from "./phar_sto";
import { useTranslation } from "react-i18next";


const Medicine = ({ isModalOpen, setIsModalOpen }) => {

    const { updateStock, renderTarget, checkManagePin, checkNationalStock, num_of_sto, num_of_phar } = useContext(AppContext);
    const [isEditing, setIsEditing] = useState(false);
    const [current_quan, setCurrent_quan] = useState(0);
    const [isPwdinputOpen, setIsPwdinputOpen] = useState(false);
    const [isPharOpen, setIsPharOpen] = useState(false);
    const [pwdValue, setPwdvalue] = useState('');
    const [msg, setMsg] = useState('');
    const {t} = useTranslation()

    const triggerCheck = ()=>{
        setIsPharOpen(true);
    }

    const toggleEdit = () => {
        setIsEditing(true);

        setCurrent_quan(renderTarget.sto_quan);

    };

    const handleClose = (e) => {

        if (e.target.className.includes('overlay')) {
            setIsEditing(false)
            setIsModalOpen(false);
        }


    };

    const handleOpenInput = () => {

        
        const newQuantity = document.getElementById('quantity_input').value;
        console.log(newQuantity+'  '+current_quan)
        if (newQuantity == current_quan) {
            setIsEditing(false);
        } else if (newQuantity < 0) {
            alert('Invalid quantity! quantity cannot be less than 0.');
            document.getElementById('quantity_input').value = current_quan;
        } else if (newQuantity >= 999) {
            alert('R U CRAZY??');
            document.getElementById('quantity_input').value = current_quan;
        } else {
            setIsPwdinputOpen(true);
        }
        
    }

    const finishEdit = (e) => {
        e.preventDefault();

        const newQuantity = document.getElementById('quantity_input').value;

            if (checkManagePin(pwdValue)) {
                updateStock(renderTarget, newQuantity);
                setMsg('');
                setPwdvalue('');
                setIsPwdinputOpen(false);
                setIsEditing(false);
            } else {
                setPwdvalue('');
                setMsg('Wrong management PIN!');
                return
            }
    }

    return (
        <>
            {isModalOpen && (
                <div className={styles.overlay} onClick={handleClose}>
                    <div className={styles.floating_window}>
                    <button className={styles.btnnnn} onClick={() => setIsModalOpen(false)}><img src='/clear.png' alt="Close" width='40' height='40' className={styles.icon_image} /></button>

                        <div className={styles.product_grid}>
                            <Pwdinput
                                isPwdinputOpen={isPwdinputOpen}
                                setIsPwdinputOpen={setIsPwdinputOpen}
                                finishEdit={finishEdit}
                                pwdValue={pwdValue}
                                setPwdvalue={setPwdvalue}
                                msg={msg}
                                setMsg={setMsg}
                                setIsEditing={setIsEditing}
                            />
                            <Phar_sto
                            isPharOpen={isPharOpen}
                            setIsPharOpen={setIsPharOpen}
                            />
                            <div className={styles.image_area}>
                                <img className={styles.img} src={renderTarget.med_url} alt={renderTarget.med_name} onClick={() => window.open(`${renderTarget.med_url}`, '_blank')} />
                            </div>
                            <div className={styles.details_area}>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_name')}</p> 
                                    <p className={styles.content}>{renderTarget.med_name}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_supplier')}</p> 
                                    <p className={styles.content}>{renderTarget.med_manufacturer}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_store_position')}</p> 
                                    <p className={styles.content}>{renderTarget.sto_place_in_store}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_stock_position')}</p> 
                                    <p className={styles.content}>{renderTarget.sto_place_in_stock}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_price')}</p>
                                    <p className={styles.content}>$ {renderTarget.sto_pri}</p> 
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_composition')}</p> 
                                    <p className={styles.content}>{renderTarget.med_composition}</p>
                                </div>
                                <div className={styles.detail_item}>
                                    <p className={styles.label}>{t('search_description')}</p> 
                                    <p className={styles.content}>{renderTarget.med_description}</p>
                                </div>
                            </div>
                            <div className={styles.quantity_area}>
                                <p className={styles.quan_label}>{t('stock_quantity')}</p>
                                {isEditing ? (
                                    <input className={styles.quan_indi} id="quantity_input" type="number" defaultValue={renderTarget.sto_quan} />
                                ) : (
                                    <span className={styles.quan_indi}>--- {renderTarget.sto_quan} ---</span>
                                )}
                                {isEditing
                                    ?
                                    <button className={styles.quan_btn} onClick={() => handleOpenInput()}>{t('stock_lock')}</button>
                                    :
                                    <button className={styles.quan_btn} onClick={() => toggleEdit()}>{t('stock_edit')}</button>
                                }
                                <p onClick={triggerCheck} className={styles.chk_cross}>{num_of_sto}{t('search_units')}{num_of_phar}{t('search_stores')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Medicine;
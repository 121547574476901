import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import styles from './search.module.css';
import Pagination_alt from './pagination_alt';
import { AppContext } from '../../Services/context';
import Medicine_alt from './medicine_small_alt';
import Image_canvas_alt from './Image_canvas_alt';
import Med_card_alt from './med_card_alt';
import Med_sticker_alt from './med_sticker_alt';
import { useTranslation } from 'react-i18next';

const Search = () => {

    const {
        allStock_alt,
        currentPage_alt,
        textUpload_alt,
        imageUpload_alt,
        stockToRender_alt,
        stockPerPage_alt,
        setStockPerPage_alt,
        setTotalPages_alt,
        setCurrentPage_alt,
        listView_alt,
        setListView_alt,
        setStockToRender_alt,
        inputValue_alt,
        setInputValue_alt,
        isFetching_alt,

        renderTarget,
        setRenderTarget,
        checkNationalStock,
    } = useContext(AppContext);

    const {t} = useTranslation()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageCanvasOpen, setIsImageCanvasOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [hasSearched, setHasSearched] = useState(false); // Track if a search has been performed

    const toggle_list_View = () => {
        handleViewChange(9)
        setListView_alt(true);
    }

    const toggle_card_View = () => {
        handleViewChange(4)
        setListView_alt(false);
    }

    const handleViewChange = (amount) => {
        const indexOfFirstItem = (currentPage_alt - 1) * stockPerPage_alt;
        const newCurrentPage = Math.floor(indexOfFirstItem / amount) + 1;
        const newTotalPages = Math.ceil(allStock_alt.length / amount);
        const updatedPage = Math.min(newCurrentPage, newTotalPages);
        setTotalPages_alt(Math.ceil(allStock_alt.length / amount));
        setStockPerPage_alt(amount);
        if (updatedPage <= 0) {
            setCurrentPage_alt(1);
        } else {
            setCurrentPage_alt(updatedPage);
        }
    }

    const toggleCanvas = (each) => {
        setIsModalOpen(!isModalOpen);
        setRenderTarget(each);

    };


    const toggleImageCanvas = () => {
        setIsMenuOpen(false);
        setIsImageCanvasOpen(true);
    };

    const textSearch = (e) => {
        e.preventDefault();
        textUpload_alt(inputValue_alt);
        setHasSearched(true); 

    };

    const fileInputRef = useRef(null);
    const openImagePicker = () => {
        fileInputRef.current.click();
    };
    const handelImageSelection = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        if (!image) {
            alert('Invalid file or you did not choose one!');
            return;
        }

        const fileExtension = image.name.split('.').pop().toLowerCase();
        if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
            alert('Please select .jpg or .png image');
        } else {
            setIsMenuOpen(false);
            imageUpload_alt(image);
            setHasSearched(true); // Set search as performed
        }
        fileInputRef.current.value = ""; 
    };

    const resetSearch = () => {
        setInputValue_alt('');
        setHasSearched(false); // Reset the search state
        setStockToRender_alt([]); // Clear the rendered stock
    };

    useEffect(() => {
        if (hasSearched) {
            const indexOfLastImage = currentPage_alt * stockPerPage_alt;
            const indexOfFirstImage = indexOfLastImage - stockPerPage_alt;
            const currentStock = allStock_alt.slice(indexOfFirstImage, indexOfFirstImage + stockPerPage_alt);
            setStockToRender_alt(currentStock);
        } else {
            setStockToRender_alt([]);
        }
    }, [currentPage_alt, allStock_alt, stockPerPage_alt]);



    useEffect(() => {
        if (renderTarget) {
            checkNationalStock();
        }

    }, [renderTarget])

    return (
        <div className={hasSearched ? styles.stockdiv : styles.stockdiv_alt}>
            <Medicine_alt
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            <Image_canvas_alt
                isImageCanvasOpen={isImageCanvasOpen}
                setIsImageCanvasOpen={setIsImageCanvasOpen}
                setHasSearched={setHasSearched}
            />

            <div className={styles.title}>

                {hasSearched ? (
                    <div className={styles.banner_logo}>
                        <img src='/pharmasee3.png' alt="PharmaSee Logo" className={styles.logo_image_alt} />
                    </div>
                ) : (
                    <div className={styles.banner_logo}>
                        <img src='/pharmasee.png' alt="PharmaSee Logo" className={styles.logo_image} />
                        <h3>{t('search_title')}</h3>
                    </div>
                )}

            </div>



            <div className={styles.input_area}>
                <div className={styles.input_div}>
                    <input
                        type="text_input"
                        className={styles.input}
                        value={inputValue_alt}
                        onChange={(e) => setInputValue_alt(e.target.value)}
                        placeholder={t('search_typetosearch')}
                    />
                    <button onClick={textSearch} className={styles.btn} type="submit"><img src='/search.png' alt="Text Search" width='20' height='20' className="icon-image" /></button>
                    <button className={styles.btn} onClick={openImagePicker}><img src='/album.png' alt="Upload for Image Search" width='20' height='20' className="icon-image" /></button>
                    <button className={styles.btn} onClick={toggleImageCanvas}><img src='/camera.png' alt="Take a Photo" width='20' height='20' className="icon-image" /></button>
                    <button onClick={resetSearch} className={styles.btn} type="submit"><img src='/clear.png' alt="Reset Search" width='20' height='20' className="icon-image" /></button>
                    <input type="file" accept=".jpg,.jpeg,.png" style={{ display: 'none' }} ref={fileInputRef} onChange={handelImageSelection} />
                </div>
            </div>

            {hasSearched && ( // Only show results after a search
                <>
                    <div className={styles.indicator}>
                        <p>{t('search_display')} {currentPage_alt * stockPerPage_alt - (stockPerPage_alt - 1)}-{Math.min(currentPage_alt * stockPerPage_alt, allStock_alt.length)} / {allStock_alt.length}</p>
                        <button onClick={listView_alt ? toggle_card_View : toggle_list_View} className={styles.switch}>{listView_alt ? t('search_list') : t('search_card')}</button>
                    </div>

                    {listView_alt && (
                        <div className={styles.list_indicator}>
                            <p className={styles.list_img}></p>
                            <p className={styles.list_product}>{t('search_product')}</p>
                            <p className={styles.list_position}>{t('search_shelf')}</p>
                            <p className={styles.list_manu}>{t('search_supplier')}</p>
                            <p className={styles.list_price}>{t('search_price')}</p>
                        </div>
                    )}

                    {isFetching_alt ? (
                        <div className={styles.fetchingDiv}><h1>{t("search_fetch")}</h1></div>
                    ) : (
                        <div className={listView_alt ? styles.med_list : styles.med_card}>
                            {stockToRender_alt.length > 0 ? (
                                stockToRender_alt.map((each, index) => (
                                    !listView_alt ? (
                                        <Med_card_alt
                                            toggleCanvas={toggleCanvas}
                                            each={each}
                                            index={index}
                                        />
                                    ) : (
                                        <Med_sticker_alt
                                            toggleCanvas={toggleCanvas}
                                            each={each}
                                            index={index}
                                        />
                                    )
                                ))
                            ) : (
                                <div className={styles.no_stock}>
                                    <h1>{t('search_nostock')}</h1>
                                </div>
                            )}
                        </div>
                    )}

                    {stockToRender_alt.length > 0 && (
                        <div className={`${styles.arrow} ${isFetching_alt ? styles.hidden : null}`}>
                            <Pagination_alt />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default Search;

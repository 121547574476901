// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_main__jVswU{
    display: flex;
    /* border: red 1px solid; */
    background-color: #cad2c5;
    min-width: 100%;
    min-height: 100%;
    overflow-x: hidden;
}

.main_content__A6Lr8{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: auto;
    margin-top: 0px;
    padding: 20px;
    /* background-color: lightgray; */
    min-height:100vh;
    min-width: 80%;
    /* width: 80%; */
    /* box-shadow: rgb(36, 36, 36) 0 3px 3px; */
    transition: transform 0.3s ease;
    background-color: #cad2c5;
}

@media (max-width: 1560px) {
    .main_content__A6Lr8 {
        transform: translateX(-315px); 
        min-width: 100%;
        margin-left: 0;
        /* padding: 0; */
        
    }
        
    
  }

`, "",{"version":3,"sources":["webpack://./src/Page/main.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;IAC3B,YAAY;IACZ,eAAe;IACf,aAAa;IACb,iCAAiC;IACjC,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,2CAA2C;IAC3C,+BAA+B;IAC/B,yBAAyB;AAC7B;;AAEA;IACI;QACI,6BAA6B;QAC7B,eAAe;QACf,cAAc;QACd,gBAAgB;;IAEpB;;;EAGF","sourcesContent":[".main{\r\n    display: flex;\r\n    /* border: red 1px solid; */\r\n    background-color: #cad2c5;\r\n    min-width: 100%;\r\n    min-height: 100%;\r\n    overflow-x: hidden;\r\n}\r\n\r\n.content{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: flex-start;\r\n    margin: auto;\r\n    margin-top: 0px;\r\n    padding: 20px;\r\n    /* background-color: lightgray; */\r\n    min-height:100vh;\r\n    min-width: 80%;\r\n    /* width: 80%; */\r\n    /* box-shadow: rgb(36, 36, 36) 0 3px 3px; */\r\n    transition: transform 0.3s ease;\r\n    background-color: #cad2c5;\r\n}\r\n\r\n@media (max-width: 1560px) {\r\n    .content {\r\n        transform: translateX(-315px); \r\n        min-width: 100%;\r\n        margin-left: 0;\r\n        /* padding: 0; */\r\n        \r\n    }\r\n        \r\n    \r\n  }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `main_main__jVswU`,
	"content": `main_content__A6Lr8`
};
export default ___CSS_LOADER_EXPORT___;

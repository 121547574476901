import React from 'react';
import { useHref } from 'react-router-dom';
import styles from './welcome.module.css';
import { useNavigate } from 'react-router-dom'; // Import the hook
import { useTranslation } from 'react-i18next';


const Welcome2 = () => {
    const navigate = useNavigate(); // Initialize the navigate function
    const {t} = useTranslation();
    return (
        <div className={styles.intro_container}>
            <div className={styles.intro_logo}>
                <img src='/pharmasee4.png' alt="Pharmasee Logo" width='350' className={styles.logo_image} />
                <h1>{t('unauthorized')}</h1>
                <br></br>
                <div dangerouslySetInnerHTML={{ __html: t('unauth_warning') }} /> 
                <button className={styles.signinButton} onClick={() => navigate('/')}>
                {t('login_signin')}
                </button>
                    <p><br></br></p>
                
            </div>
            <div className="intro-content">
                <h3>{t('login_warn_title')}</h3><br />
                <div dangerouslySetInnerHTML={{ __html: t('login_warning') }} /> 
                
            </div>
        </div>
    );
}

export default Welcome2;
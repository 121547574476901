import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import Stock from '../Components/stock/stock';
import Stores from '../Components/stores/storelocator';
import Welcome from '../Components/welcome/welcome';
import E401 from '../Components/welcome/E401';
import Search from '../Components/searching/search';
import { useTranslation } from 'react-i18next';


// import Searching from '../Components/searching/searching';
// import Uploading from '../Components/uploading/uploading';

export const AppContext = React.createContext({});

const backendAddress = process.env.REACT_APP_BACKEND_ADR


export const AppContextProvider = ({ children }) => {

    const { t } = useTranslation();
    // const [manufacturer, setManufacturer] = useState("");
    // const [productImage, setProductImage] = useState("");
    // const [description, setDescription] = useState("");
    // const [composition, setComposition] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [isFetching_alt, setIsFetching_alt] = useState(false);
    // const [fetchResult, setFetchResult] = useState(undefined);
    const [logged_in, setLogged_in] = useState(false);
    const [currentcontent, changeContent] = useState(null);
    const [pwdInput, setPwdInput] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [inputValue_alt, setInputValue_alt] = useState("");
    // const [pharmacy, setPharmacy] = useState(undefined);
    const [pharmacy, setPharmacy] = useState();
    const [totalPages, setTotalPages] = useState(1);
    const [totalPages_alt, setTotalPages_alt] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage_alt, setCurrentPage_alt] = useState(1);
    const [allStock, setAllStock] = useState([]);
    const [allStock_alt, setAllStock_alt] = useState([]);
    const [renderTarget, setRenderTarget] = useState(undefined);
    const [stockToRender, setStockToRender] = useState([]);
    const [stockToRender_alt, setStockToRender_alt] = useState([]);
    const [idInput, setIdInput] = useState('');
    const [stockPerPage, setStockPerPage] = useState(4);
    const [stockPerPage_alt, setStockPerPage_alt] = useState(4);
    const [listView, setListView] = useState(false);
    const [listView_alt, setListView_alt] = useState(false);
    const [nationalSto, setNationalSto] = useState([]);
    const [num_of_phar, set_num_of_phar] = useState(0);
    const [num_of_sto, set_num_of_sto] = useState(0);
    const [current_language, set_current_language] = useState('en');


    const checkNationalStock = async () => {

        await axios.post(`https://${backendAddress}/checkNationalStock`, { m_id: renderTarget.med_index })
            .then((res) => {

                if (res.status === 200) {
                    set_num_of_phar(0);
                    set_num_of_sto(0);
                    setNationalSto([]);
                    setNationalSto(res.data.fetchResult)
                    set_num_of_phar(res.data.fetchResult.length)
                    let total = 0;
                    for (let item of res.data.fetchResult) {
                        total += item.sto_quan
                    }
                    set_num_of_sto(total)
                }

            }).catch((error) => {
                if (error.response.status === 404) {
                    alert(error.response.data.msg)
                    setIsFetching(false)
                } else {
                    alert(error)
                    setIsFetching(false)
                }
            })


    }

    const textUpload_alt = async (textInput) => {
        setIsFetching_alt(true)

        await axios.post(`https://${backendAddress}/textSearch`, { input: textInput })
            .then((res) => {
                if (res.status === 200) {
                    setCurrentPage_alt(1)
                    setAllStock_alt(res.data.fetchResult.rows);
                    setTotalPages_alt(Math.ceil(res.data.fetchResult.rows.length / stockPerPage_alt));
                    setIsFetching_alt(false)
                }
            }).catch((error) => {
                if (error.response.status === 404) {
                    setIsFetching_alt(false)
                } else {
                    alert(error)
                    setIsFetching_alt(false)
                }
            })
    };

    const textUpload = async (textInput) => {
        setIsFetching(true)

        await axios.post(`https://${backendAddress}/textSearch_webui`, { input: textInput, pid: pharmacy.pha_id })
            .then((res) => {
                if (res.status === 200) {
                    setCurrentPage(1)
                    setAllStock(res.data.fetchResult);
                    setTotalPages(Math.ceil(res.data.fetchResult.length / stockPerPage));
                    setIsFetching(false);
                }
            }).catch((error) => {
                if (error.response.status === 404) {
                    alert(error.response.data.msg)
                    setIsFetching(false)
                } else {
                    alert(error)
                    setIsFetching(false)
                }
            })
    };

    const imageUpload = async (imageInput) => {
        setIsFetching(true)
        const data = new FormData();
        data.append('image', imageInput);

        await axios.post(`https://${backendAddress}/imageSearch_webui`, data, { headers: { 'Content-Type': 'multipart/form-data', pid: `${pharmacy.pha_id}` } })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data.fetchResult)
                    setCurrentPage(1)
                    setAllStock(res.data.fetchResult);
                    setTotalPages(Math.ceil(res.data.fetchResult.length / stockPerPage));
                    setIsFetching(false)
                }

            }).catch((error) => {
                if (error.response.status === 500) {
                    alert(error.response.data.msg)
                    setIsFetching(false)
                } else if (error.response.status === 404) {
                    if (error.response.data.msg === '0') {
                        alert(t('context_noproduct1'))
                        setIsFetching(false)
                    } else if (error.response.data.msg === '2') {
                        alert(t('context_noproduct2'))
                        setIsFetching(false)
                    } else if (error.response.data.msg === '3') {
                        alert(t('context_noproduct3'))
                        setIsFetching(false)
                    }
                } else {
                    alert(error)
                    setIsFetching(false)

                }
            }).finally(
                setInputValue('')
            )
    }

    const imageUpload_alt = async (imageInput) => {
        setIsFetching_alt(true)
        const data = new FormData();
        data.append('image', imageInput);

        await axios.post(`https://${backendAddress}/imageSearch`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data.fetchResult.rows)
                    setCurrentPage_alt(1)
                    setAllStock_alt(res.data.fetchResult.rows);
                    setTotalPages_alt(Math.ceil(res.data.fetchResult.rows.length / stockPerPage_alt));
                    setIsFetching_alt(false)
                }

            }).catch((error) => {
                if (error.response.status === 500) {
                    alert(error.response.data.msg)
                    setIsFetching_alt(false)
                } else if (error.response.status === 404) {
                    if (error.response.data.msg === '0') {
                        alert(t('context_noproduct1'))
                        setIsFetching_alt(false)
                    } else if (error.response.data.msg === '2') {
                        alert(t('context_noproduct2'))
                        setIsFetching_alt(false)
                    } else if (error.response.data.msg === '3') {
                        alert(t('context_noproduct3'))
                        setIsFetching_alt(false)
                    }
                } else {
                    alert(error)
                    setIsFetching_alt(false)
                }
            }).finally(
                setInputValue_alt('')
            )
    }

    const pha_login = async () => {

        if (pwdInput === '' || idInput === '') {
            return;
        }

        try {
            const loginRes = await axios.post(`https://${backendAddress}/Login_webui`, { code: pwdInput, id: idInput });

            if (loginRes.status === 200) {
                const phar = loginRes.data.fetchResult.rows[0];
                setPharmacy(phar);
                setLogged_in(true);
                setPwdInput('');
                setIsFetching(true);

                const stockRes = await axios.post(`https://${backendAddress}/checkStock_webui`, { pid: phar.pha_id });

                if (stockRes.status === 200) {
                    setAllStock(stockRes.data.fetchResult);
                    setTotalPages(Math.ceil(stockRes.data.fetchResult.length / stockPerPage));
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                alert(t('context_invalid_passcode'));
            } else {
                alert(error.message || t('context_error_occured'));
            }
        } finally {
            setPwdInput('');
            setIsFetching(false);
        }
    };

    const refreshStock = async () => {
        setIsFetching(false);
        try {

            const stockRes = await axios.post(`https://${backendAddress}/checkStock_webui`, { pid: pharmacy.pha_id });

            if (stockRes.status === 200) {
                setAllStock(stockRes.data.fetchResult);
                setTotalPages(Math.ceil(stockRes.data.fetchResult.length / stockPerPage));
                setCurrentPage(1);
                setInputValue('')
                setIsFetching(false);
            }

        } catch (error) {
            if (error.response && error.response.status === 404) {
                alert(error.response.data.msg);
            } else {
                alert(error.message || t('context_error_occured'));
            }
        }
        setIsFetching(false);
    }

    const updateStock = async (target, num) => {

        // setIsFetching(true);
        try {
            const up_res = await axios.post(`https://${backendAddress}/modifyStock_webui`, { stockID: target.sto_id, num: num, pid: pharmacy.pha_id });

            if (up_res.status === 200) {

                const new_stock_to_render = stockToRender.map(item => {
                    if (item.sto_id === target.sto_id) {
                        return { ...item, sto_quan: num }
                    }
                    return item
                })

                setStockToRender(new_stock_to_render)

                renderTarget.sto_quan = num;
                setRenderTarget(renderTarget)
                setIsFetching(false)
            }
        } catch (error) {
            alert(error.response.data.msg)
            // setIsFetching(false);
        }
    }

    const checkManagePin = (pin) => {

        if (pin === pharmacy.pha_code) {
            return true;
        } else {
            return false;
        }
    }

    const lockConsole = () => {
        setLogged_in(false)
        setPwdInput('')
        changeContent(null)
        setPharmacy(undefined)
        setIsFetching(false);
        setIsFetching_alt(false);
        setAllStock([]);
        setAllStock_alt([]);
        setIdInput('');
    }

    const showcontent = () => {
        switch (currentcontent) {
            case "stock":
                return <Stock />;
            case "stores":
                return <Stores />;
            case "search":
                return <Search />;
            // case "searching":
            //     return <Searching />;
            // case "uploading":
            //     return <Uploading />;
            default:
                if (!logged_in) {
                    return <E401 />;
                }
                else {
                    return <Welcome />;
                }

        }
    };


    const context = {
        textUpload,
        textUpload_alt,
        imageUpload,
        imageUpload_alt,
        backendAddress,
        // fetchResult,
        // manufacturer,
        // description,
        // composition,
        isFetching,
        // productImage,
        pwdInput,
        setPwdInput,
        pha_login,
        logged_in,
        setLogged_in,
        currentcontent,
        changeContent,
        showcontent,
        inputValue,
        setInputValue,
        lockConsole,
        totalPages,
        totalPages_alt,
        setTotalPages,
        currentPage,
        setCurrentPage,
        allStock,
        pharmacy,
        refreshStock,
        renderTarget,
        setRenderTarget,
        updateStock,
        stockToRender,
        setStockToRender,
        setStockToRender_alt,
        checkManagePin,
        idInput,
        setIdInput,
        stockPerPage,
        setStockPerPage,
        listView,
        setListView,
        nationalSto,
        checkNationalStock,
        num_of_phar,
        num_of_sto,
        allStock_alt,
        currentPage_alt,
        stockToRender_alt,
        stockPerPage_alt,
        setStockPerPage_alt,
        setTotalPages_alt,
        setCurrentPage_alt,
        listView_alt,
        setListView_alt,
        inputValue_alt,
        setInputValue_alt,
        isFetching_alt,
        setIsFetching_alt,
        current_language,
        set_current_language
    };

    return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}

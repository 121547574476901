import React from "react";
import styles from './med_sticker_alt.module.css';



const Med_sticker_alt = ({
    each,
    index,
    toggleCanvas
}) => {
    return (
        <div onClick={() => toggleCanvas(each)} key={index} className={styles.outer}>
            <img className={`${styles.img} ${each.sto_quan==0?styles.out:null}`} src={each.med_url}></img>
            <p className={styles.med_name}>{each.med_name}</p>
            <p className={styles.place}>{each.sto_place_in_store}</p>
            <p className={styles.manu}>{each.med_manufacturer}</p>
            <p className={styles.price}>{each.sto_pri}</p>
        </div>
    )
}

export default Med_sticker_alt;
import React from "react";
import styles from './login.module.css';
import { AppContext } from "../Services/context";
import { useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18n from 'i18next';

const Login = () => {

    const {
        pwdInput,
        setPwdInput,
        idInput,
        setIdInput,
        pha_login,
        logged_in,
        current_language,
        set_current_language
    } = useContext(AppContext);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handle_login = () => {
        pha_login();
    };

    useEffect(() => {
        if (logged_in) {
            navigate('/main');
        } else {
            navigate('/');
        }
    }, [logged_in])

    // Handle forgot password link click
    const handleForgotPassword = (e) => {
        e.preventDefault();
        alert(t('login_forgot'));
    };

    const aboutFRW = (e) => {
        e.preventDefault();
        alert("Copyright © 1992~2024 FRW Healthcare Limited. \nAll rights reserved. \n\nConstructed by RGB Consulting \nAuckland, New Zealand");
    };

    const toggle_eng = () => {
        set_current_language('en');
        i18n.changeLanguage('en')

    };
    const toggle_maori = () => {
        set_current_language('ma');
        i18n.changeLanguage('ma')

    };

    const toggle_cn = () => {
        set_current_language('cn');
        i18n.changeLanguage('cn')

    };

    const toggle_jp = () => {
        set_current_language('jp');
        i18n.changeLanguage('jp')
    };

    const toggle_de = () => {
        set_current_language('de');
        i18n.changeLanguage('de')
    };

    const toggle_kr = () => {
        set_current_language('kr');
        i18n.changeLanguage('kr')
    }

    return (
        <div className={styles.outer}>
            
            <div className={styles.inner}>
                <h1>{t('login_title')}</h1>
                <div className={styles.warning}>
                    <h3>{t('login_warn_title')}</h3><br />
                    <div dangerouslySetInnerHTML={{ __html: t('login_warning') }} />  {/* Renders HTML content */}
                </div>


                <div className={styles.login_div}>


                    <div className={styles.id_div}>

                        {/* <h4>User ID</h4> */}
                        <input
                            type="text"
                            className={styles.input}
                            value={idInput}
                            onChange={(e) => setIdInput(e.target.value)}
                            placeholder={t('login_id')}
                        />
                    </div>

                    <div className={styles.pin_div}>
                        {/* <h4>Code of today</h4> */}
                        <input
                            type="password"
                            className={styles.input}
                            value={pwdInput}
                            onChange={(e) => setPwdInput(e.target.value)}
                            placeholder={t('login_pacd')}
                        />
                    </div>



                    <a href="#" onClick={handleForgotPassword} className={styles.forgotPassword}>{t('login_about')}</a>

                    <button className={styles.signinButton} onClick={handle_login}>
                        {t('login_signin')}
                    </button>

                </div>

                <img src='/frw1.png' alt="FRW Healthcare Logo" className={styles.logo} />
                <a href="#" onClick={aboutFRW} className={styles.forgotPassword}>{t('login_copyright')}</a>

            </div>
            <div className={styles.lan}>
                <button className={`${styles.lan_btn} ${current_language === "en" ? styles.selected : null}`} onClick={toggle_eng}>English</button>
                <button className={`${styles.lan_btn} ${current_language === "ma" ? styles.selected : null}`} onClick={toggle_maori}>Māori</button>
                <button className={`${styles.lan_btn} ${current_language === "de" ? styles.selected : null}`} onClick={toggle_de}>Deutsch</button>
                <button className={`${styles.lan_btn} ${current_language === "cn" ? styles.selected : null}`} onClick={toggle_cn}>中文</button>
                <button className={`${styles.lan_btn} ${current_language === "jp" ? styles.selected : null}`} onClick={toggle_jp}>日本語</button>
                <button className={`${styles.lan_btn} ${current_language === "kr" ? styles.selected : null}`} onClick={toggle_kr}>한국어</button>
            </div>
        </div>
    );
};

export default Login;

import React, { useContext, useEffect, useState } from "react";
import styles from "./main.module.css"
import MainSidebar from "../Components/mainSidebar/mainSidebar";
import { AppContext } from "../Services/context";

const Main =()=>{

const  {showcontent} = useContext(AppContext)


  return(
    
    <div className={styles.main}>
      
      <MainSidebar/>
      <div className={styles.content}>{showcontent()}</div>
    </div>
  )

}

export default Main;

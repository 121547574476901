import React, { useState } from 'react';
import './storelocator.css';
import { useTranslation } from 'react-i18next';

const StoreLocator = () => {
    // State for selected store
    const [selectedStore, setSelectedStore] = useState('Auckland - CBD / Karangahape Rd');

    const { t } = useTranslation()
    // Placeholder data for stores
    const stores = [
        {
            name: 'Auckland - CBD / Karangahape Rd',
            address: '445 Karangahape Road, Auckland Central 1001',
            hours: '  \n Mon ~ Fri 9:00 AM ~ 7:00 PM \n Sat 10:00 AM ~ 6:00 PM \n Sun 10:00 AM ~ 4:00 PM',
            phone: '09-8462375',
            email: 'aklkr@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6384.683216849225!2d174.7528590765844!3d-36.858241272230536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47ea5a16155b%3A0x167ddd4e5780ca0!2s445%20Karangahape%20Road%2C%20Auckland%20CBD%2C%20Auckland%201010!5e0!3m2!1sen-NZ!2snz!4v1724635600313!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Alice Sonara', email: 'asonara@frwhealthcare.co.nz', phone: '027-7608094', avatar: '/avatars/asonara.jpeg' },
            pharmacists: [
                { name: 'Sue Wong', email: 'swong@frwhealthcare.co.nz', phone: '021-5557767', avatar: '/avatars/swong.jpeg' },
                { name: 'Imran Malik', email: 'imalik@frwhealthcare.co.nz', phone: '022-7655721', avatar: '/avatars/imalik.jpeg' },
            ]
        },
        {
            name: 'Auckland - CBD / Wellesley St',
            address: '54 Wellesley Street West, Auckland Central 1010',
            hours: '  \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM',
            phone: '09-8531920',
            email: 'aklws@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.6708005741466!2d174.75946807658414!3d-36.85035947223274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47efe585fc0b%3A0xe58ab7ae88543af7!2s54%20Wellesley%20Street%20West%2C%20Auckland%20CBD%2C%20Auckland%201010!5e0!3m2!1sen-NZ!2snz!4v1724635620839!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'John Devon', email: 'jdevon@frwhealthcare.co.nz', phone: '021-4141379', avatar: '/avatars/jdevon.jpeg' },
            pharmacists: [
                { name: 'Alan Smith', email: 'asmith@frwhealthcare.co.nz', phone: '022-1313167', avatar: '/avatars/asmith.jpeg' },
                { name: 'Noel Bathura', email: 'nbathura@frwhealthcare.co.nz', phone: '027-7564571', avatar: '/avatars/nbathura.jpeg' },
            ]
        },
        {
            name: 'Auckland - Newmarket',
            address: '220 Broadway, Newmarket 1023',
            hours: '  \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM',
            phone: '09-9174832',
            email: 'aklnm@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.889082616632!2d174.7754399765848!3d-36.86907367222754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d4876d7ae4fcb%3A0xd11fd2eaa7f28f7e!2s220%20Broadway%2C%20Newmarket%2C%20Auckland%201023!5e0!3m2!1sen-NZ!2snz!4v1724635667579!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Heather Dowson', email: 'hdowson@frwhealthcare.co.nz', phone: '022-1724784', avatar: '/avatars/hdowson.jpeg' },
            pharmacists: [
                { name: 'Lawrance Sanford', email: 'lsanford@frwhealthcare.co.nz', phone: '027-1235367', avatar: '/avatars/lsanford.jpeg' },
                { name: 'Emanuel Durant', email: 'edurant@frwhealthcare.co.nz', phone: '022-6655257', avatar: '/avatars/edurant.jpeg' },
            ]
        },
        {
            name: 'Auckland - Rosedale',
            address: '9/83 Apollo Drive, Rosedale 0632',
            hours: '  \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM',
            phone: '09-6237419',
            email: 'aklrd@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3197.31678949894!2d174.72447267658012!3d-36.7389664722648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d3bcdc4dcabf9%3A0x52a77faa60f1d6ee!2s9%2F83%20Apollo%20Drive%2C%20Rosedale%2C%20Auckland%200632!5e0!3m2!1sen-NZ!2snz!4v1724635689946!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Yujie Li', email: 'yli@frwhealthcare.co.nz', phone: '027-8848675', avatar: '/avatars/yli.jpeg' },
            pharmacists: [
                { name: 'Stephanie Lee', email: 'slee@frwhealthcare.co.nz', phone: '022-3135467', avatar: '/avatars/slee.jpeg' },
                { name: 'Yanfen Zhang', email: 'yzhang@frwhealthcare.co.nz', phone: '021-7131443', avatar: '/avatars/yzhang.jpeg' },
            ]
        },
        {
            name: 'Auckland - Westgate',
            address: '21 Fred Taylor Drive, Massey 0814',
            hours: '  \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM',
            phone: '09-7384921',
            email: 'aklwg@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.9358555036883!2d174.6035728765831!3d-36.82005697224139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d3fe2a046ada9%3A0x2a145bf5fdf68007!2s21%20Fred%20Taylor%20Drive%2C%20Westgate%2C%20Auckland%200814!5e0!3m2!1sen-NZ!2snz!4v1724635645178!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Arnold Lee', email: 'alee@frwhealthcare.co.nz', phone: '022-3135467', avatar: '/avatars/alee.jpeg' },
            pharmacists: [
                { name: 'Ravi Kishore', email: 'rkishore@frwhealthcare.co.nz', phone: '021-1131867', avatar: '/avatars/rkishore.jpeg' },
                { name: 'Margaret Dewey', email: 'mdewey@frwhealthcare.co.nz', phone: '027-3118748', avatar: '/avatars/mdewey.jpeg' },
            ]
        },
        {
            name: 'Christchurch - CBD / Manchester St',
            address: '177 Manchester Street, Christchurch Central 8011',
            hours: ' \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM',
            phone: '03-3764912',
            email: 'chcms@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.5398388826043!2d172.6346205914949!3d-43.532787696612466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a22e4a247a1%3A0xe5d3a0edc48b8572!2s177%20Manchester%20Street%2C%20Christchurch%20Central%20City%2C%20Christchurch%208011!5e0!3m2!1sen-NZ!2snz!4v1724635718947!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Ann Somers', email: 'asomers@frwhealthcare.co.nz', phone: '027-9842671', avatar: '/avatars/asomers.jpeg' },
            pharmacists: [
                { name: 'Patrick Smith', email: 'psmith@frwhealthcare.co.nz', phone: '022-1664467', avatar: '/avatars/psmith.jpeg' },
                { name: 'Bob Johnson', email: 'bjohnson@frwhealthcare.co.nz', phone: '021-9867311', avatar: '/avatars/bjohnson.jpeg' },
            ]
        },
        {
            name: 'Christchurch - St Albans',
            address: '2/95 Westminster Street, Saint Albans 8014',
            hours: ' \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM', phone: '03-2817463',
            email: 'chcsa@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2893.812558054644!2d172.63370217684295!3d-43.50624567110957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318bcff4bf52b3%3A0x27dc6dba9b6d98d!2s2%2F95%20Westminster%20Street%2C%20St%20Albans%2C%20Christchurch%208014!5e0!3m2!1sen-NZ!2snz!4v1724635750650!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'John Glenn', email: 'jglenn@frwhealthcare.co.nz', phone: '027-8155687', avatar: '/avatars/jglenn.jpeg' },
            pharmacists: [
                { name: 'Ayesha Khan', email: 'akhan@frwhealthcare.co.nz', phone: '022-3335577', avatar: '/avatars/akhan.jpeg' },
                { name: 'Maria Gonzalez', email: 'mgonzalez@frwhealthcare.co.nz', phone: '021-4437621', avatar: '/avatars/mgonzalez.jpeg' },
            ]
        },
        {
            name: 'Christchurch - Riccarton',
            address: '105A Riccarton Road, Riccarton 8011',
            hours: ' \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM',
            phone: '03-4912678',
            email: 'chcrt@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.682443972984!2d172.59838877684388!3d-43.52981437110837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318af460c1e80f%3A0x695a6e02cb378770!2s105%20Riccarton%20Road%2C%20Riccarton%2C%20Christchurch%208041!5e0!3m2!1sen-NZ!2snz!4v1724635435554!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Anastasia Kovaleva', email: 'akovaleva@frwhealthcare.co.nz', phone: '021-8747121', avatar: '/avatars/akovaleva.jpeg' },
            pharmacists: [
                { name: 'Noah Willys', email: 'nwillys@frwhealthcare.co.nz', phone: '021-4556867', avatar: '/avatars/nwillys.jpeg' },
                { name: 'Phoebe Clarent', email: 'pclarent@frwhealthcare.co.nz', phone: '022-9785421', avatar: '/avatars/pclarent.jpeg' },
            ]
        },
        {
            name: 'Christchurch - Super Clinic',
            address: '68 St Asaph Street, Riccarton 8011',
            hours: ' \n Mon ~ Fri    7:00 AM ~ 9:00 PM \n Sat    9:00 AM ~ 9:00 PM \n Sun   9:00 AM ~ 6:00 PM',
            phone: '03-8472931',
            email: 'chcsc@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.3509318258!2d172.6262055768442!3d-43.536726171107844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a14fd310111%3A0xe3200c3ba04a3240!2s68%20Saint%20Asaph%20Street%2C%20Christchurch%20Central%20City%2C%20Christchurch%208011!5e0!3m2!1sen-NZ!2snz!4v1724635392127!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Patricia Williams', email: 'pwilliams@frwhealthcare.co.nz', phone: '027-7877211', avatar: '/avatars/pwilliams.jpeg' },
            pharmacists: [
                { name: 'Sofia Martinez', email: 'smartinez@frwhealthcare.co.nz', phone: '021-1145477', avatar: '/avatars/smartinez.jpeg' },
                { name: 'Gunnar Gustavson', email: 'ggustavson@frwhealthcare.co.nz', phone: '022-7424331', avatar: '/avatars/ggustavson.jpeg' },
            ]
        },
        {
            name: 'Wellington - CBD / Lambton Quays',
            address: '147 Lambton Quay, Wellington 6011',
            hours: '  \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM',
            phone: '04-7529134',
            email: 'wltlq@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5996.495057495491!2d174.77142509087528!3d-41.28171699734768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d38ae2b2203a83f%3A0x8f250a1f2cb59eea!2s147%20Lambton%20Quay%2C%20Wellington%20Central%2C%20Wellington%206011!5e0!3m2!1sen-NZ!2snz!4v1724635462827!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Pavel Kostenko', email: 'pkostenko@frwhealthcare.co.nz', phone: '021-7778871', avatar: '/avatars/pkostenko.jpeg' },
            pharmacists: [
                { name: 'Howard Gilles', email: 'hgilles@frwhealthcare.co.nz', phone: '027-2249667', avatar: '/avatars/hgilles.jpeg' },
                { name: 'Samantha Larston', email: 'slarston@frwhealthcare.co.nz', phone: '022-6233921', avatar: '/avatars/slarston.jpeg' },
            ]
        },
        {
            name: 'Wellington - Lower Hutt',
            address: '15-17 Dudley Street, Lower Hutt 5010',
            hours: ' \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM',
            phone: ' 04-6392185',
            email: 'wltlh@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001.5785528555125!2d174.89889237674961!3d-41.209160971323065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d38aa559b7683e1%3A0xd0ced3492a49b0be!2s17%20Dudley%20Street%2C%20Hutt%20Central%2C%20Lower%20Hutt%205010!5e0!3m2!1sen-NZ!2snz!4v1724635573850!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Jennifer Ariston', email: 'jariston@frwhealthcare.co.nz', phone: '027-1745280', avatar: '/avatars/jariston.jpeg' },
            pharmacists: [
                { name: 'Louise Dudley', email: 'ldudley@frwhealthcare.co.nz', phone: '022-3296167', avatar: '/avatars/ldudley.jpeg' },
                { name: 'Chris Hohl', email: 'chohl@frwhealthcare.co.nz', phone: '027-7748409', avatar: '/avatars/chohl.jpeg' },
            ]
        },
        {
            name: 'Queenstown',
            address: '24 Beach Street, Queenstown 9300',
            hours: ' \n Mon ~ Fri    9:00 AM ~ 7:00 PM \n Sat    10:00 AM ~ 6:00 PM \n Sun   10:00 AM ~ 4:00 PM',
            phone: '03-7481294',
            email: 'qtn@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2819.659619711575!2d168.65764977690722!3d-45.03183477107008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa9d51d815b61a9eb%3A0xb25a6f18cd8c6b35!2s24%20Beach%20Street%2C%20Queenstown%209300!5e0!3m2!1sen-NZ!2snz!4v1724635513041!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Frank Rowland', email: 'frowland@frwhealthcare.co.nz', phone: '027-7815687', avatar: '/avatars/frowland.jpeg' },
            pharmacists: [
                { name: 'Sandra Wales', email: 'swales@frwhealthcare.co.nz', phone: '022-4167941', avatar: '/avatars/swales.jpeg' },
                { name: 'Ursula Becker', email: 'ubecker@frwhealthcare.co.nz', phone: '022-6395651', avatar: '/avatars/ubecker.jpeg' },
            ]
        },
        {
            name: 'Warehouse',
            address: '10 & 11/8 Islington Avenue, Islington 8042',
            hours: ' \n Mon ~ Fri    9:00 AM ~ 6:00 PM \n Sat    10:00 AM ~ 5:00 PM \n Sun   Closed',
            phone: '03-7355608',
            email: 'warehouse@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001.5785528555125!2d174.89889237674961!3d-41.209160971323065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d38aa559b7683e1%3A0xd0ced3492a49b0be!2s17%20Dudley%20Street%2C%20Hutt%20Central%2C%20Lower%20Hutt%205010!5e0!3m2!1sen-NZ!2snz!4v1724635488669!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Richard Strafford', email: 'rstrafford@frwhealthcare.co.nz', phone: '021-8355847', avatar: '/avatars/rstrafford.jpeg' },
            pharmacists: []
        },
        {
            name: 'Head Office',
            address: '68 St Asaph Street, Riccarton 8011',
            hours: ' \n Mon ~ Fri    9:00 AM ~ 6:00 PM \n Sat    10:00 AM ~ 5:00 PM \n Sun   Closed',
            phone: '03-1919810',
            email: 'headoffice@frwhealthcare.co.nz',

            mapEmbedCode: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.3509318258!2d172.6262055768442!3d-43.536726171107844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a14fd310111%3A0xe3200c3ba04a3240!2s68%20Saint%20Asaph%20Street%2C%20Christchurch%20Central%20City%2C%20Christchurch%208011!5e0!3m2!1sen-NZ!2snz!4v1724635392127!5m2!1sen-NZ!2snz" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',

            manager: { name: 'Edward Neumund', email: 'eneumund@frwhealthcare.co.nz', phone: '027-7578629', avatar: '/avatars/eneumund.jpeg' },
            pharmacists: [
                { name: 'Gustavo Fernandez', email: 'gfernandez@frwhealthcare.co.nz', phone: '022-4941876', avatar: '/avatars/gfernandez.jpeg' },
                { name: 'Lisa Luton', email: 'lluton@frwhealthcare.co.nz', phone: '022-6515687', avatar: '/avatars/lluton.jpeg' },
            ]
        },

        // Add more stores here...
    ];

    // Handle store selection
    const handleStoreChange = (e) => {
        setSelectedStore(e.target.value);
    };

    // Get selected store data
    const storeData = stores.find(store => store.name === selectedStore);

    return (
        <div className="store-locator-container">

            <div className="title">
                <h1>{t('store_title')}</h1>
                <div className="store-selector">
                    <select id="storeDropdown" value={selectedStore} onChange={handleStoreChange}>
                        {stores.map(store => (
                            <option key={store.name} value={store.name}>{store.name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="store-left">

                {storeData && (
                    <div className="store-details">
                        <div className="detail_div">
                            <h2 className="detail_div_title">{storeData.name}</h2>
                            <p className="detail_div_addr"><strong>{t('store_address')}</strong> {storeData.address}</p>
                            <p className="detail_div_hour"><strong>{t('store_business_hours')}</strong>
                                <span dangerouslySetInnerHTML={{ __html: storeData.hours.replace(/\n/g, '<br/>') }}></span></p>
                            <p className="detail_div_phone"><strong>{t('store_phone')}</strong> {storeData.phone}</p>
                            <p className="detail_div_mail"><strong>{t('store_email')}</strong> <a href={`mailto:${storeData.email}`}>{storeData.email}</a></p>
                        </div>
                        <div className="store-map-container">
                            <div className="store-map" dangerouslySetInnerHTML={{ __html: storeData.mapEmbedCode }}></div>
                        </div>

                    </div>
                )}
            </div>

            <div className="store-right">
                {storeData && (
                    <>
                        <h3>{t('store_manager')}</h3>
                        <div className="manager">
                            <img src={storeData.manager.avatar} className="avatar" alt={storeData.manager.name} />
                            <p><strong>{storeData.manager.name}</strong></p>
                            <p>{t('store_email')} <a className="email" href={`mailto:${storeData.manager.email}`}>{storeData.manager.email}</a></p>
                            <p>{t('store_phone')} {storeData.manager.phone}</p>
                        </div>
                        <br></br>
                        <h3>{t('store_pharmacists')}</h3>
                        <ul className="pharmacists-list">
                            {storeData.pharmacists.map(pharmacists => (
                                <li key={pharmacists.email}>
                                    <img src={pharmacists.avatar} className="avatar" alt={`${pharmacists.name}'s avatar`} />
                                    <p><strong>{pharmacists.name}</strong></p>
                                    <p>{t('store_email')} <a className="email" href={`mailto:${pharmacists.email}`}>{pharmacists.email}</a></p>
                                    <p>{t('store_phone')} {pharmacists.phone}</p>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </div>
    );
};

export default StoreLocator;

import React, { useState, useEffect } from 'react';
import styles from './welcome.module.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';  // Import i18next to get the current language

const Welcome = () => {
    const [time, setTime] = useState(new Date());
    const [greeting, setGreeting] = useState('');
    const { t } = useTranslation();  // Translation hook

    const handleHelp = (e) => {
        e.preventDefault();
        alert(t('help_instr'));
    };

    useEffect(() => {
        // Update time every second
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        // Update greeting based on the current time
        const currentHour = time.getHours();
        if (currentHour < 12) {
            setGreeting(t('greet_am'));
        } else if (currentHour < 18) {
            setGreeting(t('greet_pm'));
        } else {
            setGreeting(t('greet_ln'));
        }
    }, [time, t]);

    // Māori translations for weekdays and months
    const maoriWeekdays = ['Rātapu', 'Rāhina', 'Rātū', 'Rāapa', 'Rāpare', 'Rāmere', 'Rāhoroi'];
    const maoriMonths = ['Kohi-tātea', 'Hui-tanguru', 'Poutū-te-rangi', 'Paenga-whāwhā', 'Haratua', 'Pipiri', 'Hōngongoi', 'Here-turi-kōkā', 'Mahuru', 'Whiringa-ā-nuku', 'Whiringa-ā-rangi', 'Hakihea'];

    // Function to format the date based on the current language
    const formatDateForLocale = (time) => {
        const locale = i18n.language;  // Get the current language from i18n

        if (locale === 'ma') {  // Māori
            const weekday = maoriWeekdays[time.getDay()];
            const month = maoriMonths[time.getMonth()];
            const day = time.getDate();
            const year = time.getFullYear();
            return `${weekday}, ${day} ${month} ${year}`;
        } else if (locale === 'cn') {  // Chinese
            return time.toLocaleDateString('zh-CN', {
                weekday: 'long', // e.g., 星期一 (Monday)
                year: 'numeric',
                month: 'long', // e.g., 九月 (September)
                day: 'numeric'  // e.g., 9
            });
        } else if (locale === 'kr') {  // Chinese
            return time.toLocaleDateString('ko-KR', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        } else if (locale === 'jp') {  // Chinese
            return time.toLocaleDateString('ja', {
                weekday: 'long',
                year: 'numeric',
                month: 'long', 
                day: 'numeric'
            });
        } else if (locale === 'de') {  // Chinese
            return time.toLocaleDateString('de-de', {
                weekday: 'long',
                year: 'numeric',
                month: 'long', 
                day: 'numeric'
            });
        } else {  // Default to English (New Zealand)
            return time.toLocaleDateString('en-NZ', {
                weekday: 'long', // e.g., Monday
                year: 'numeric',
                month: 'long', // e.g., September
                day: 'numeric'  // e.g., 9
            });
        }
    };

    return (
        <div className={styles.intro_container}>
            <div className={styles.intro_logo}>
                <img src='/pharmasee4.png' alt="Pharmasee Logo" width='350' className={styles.logo_image} />
                <h4>{t('slogan')}</h4>
                <p>{t('greet_slogan')}</p>
                <p>
                <br></br>
                <a href="#" onClick={handleHelp} className={styles.signinButton}>{t('help')}</a>
                <br></br></p>
            </div>

            <div className={styles.greeting_section}>
                <h2>{greeting}</h2>  {/* Localized "Team" */}
                <h3>{time.toLocaleTimeString()}</h3>
                <h4>{formatDateForLocale(time)}</h4>
                <img src='/pill_trans.png' alt="Pharmasee Logo" width='350' className={styles.logo_image} />
            </div>
        </div>
    );
}

export default Welcome;

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React from 'react';
import Main from './Page/main';
import Home from './Components/home'
import Login from './Page/login';
import { AppContextProvider } from './Services/context';
import styles from './App.module.css'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import en from './Services/en.json';
import ma from './Services/ma.json';
import cn from './Services/cn.json';
import de from './Services/de.json';
import jp from './Services/jp.json';
import kr from './Services/kr.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      ma: { translation: ma },
      cn: { translation: cn },
      de: { translation: de },
      jp: { translation: jp },
      kr: { translation: kr },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Login />
    },
    {
      path: '/main',
      element: <Main />
    }
  ])
  return (
    // <div>
    //   <h1>
    //     Hello world!
    //   </h1>
    // </div>
    <I18nextProvider i18n={i18n}>
      <div className={styles.app}>
        <AppContextProvider>
          <RouterProvider router={router} />
        </AppContextProvider>
      </div>
    </I18nextProvider>
  );
}

export default App;

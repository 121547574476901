
import { useState, useContext } from "react";
import { AppContext } from "../../Services/context";
import styles from './pagination_alt.module.css'

const Pagination_alt = ()=>{

    const {totalPages_alt, currentPage_alt, setCurrentPage_alt} = useContext(AppContext)
    

    const handlePrevClick = () => {
        if (currentPage_alt > 1) {
            const newPage = currentPage_alt - 1;
            setCurrentPage_alt(newPage);
        }
    };

    const handleNextClick = () => {
        if (currentPage_alt < totalPages_alt) {
            const newPage = currentPage_alt + 1;
            setCurrentPage_alt(newPage);
        }
    };

    return (
        <div className={styles.pagination}>
            <button onClick={handlePrevClick} disabled={currentPage_alt === 1} className="arrow">&laquo;</button>
            <span className={styles.page_number}>{currentPage_alt}</span>
            <span className={styles.page_number}>/</span>
            <span className={styles.page_number}>{totalPages_alt}</span>
            <button onClick={handleNextClick} disabled={currentPage_alt === totalPages_alt} className="arrow">&raquo;</button>
        </div>
    );


}

export default Pagination_alt;
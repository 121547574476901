import React, { useState, useContext, useRef } from "react"
import styles from "./home.module.css"
import { AppContext } from "../Services/context";

export default function Home() {
    const {
        textUpload,
        imageUpload,
        fetchResult,
        isFetching,
        manufacturer,
        description,
        composition,
        productImage,
        checkPWD,
        pwdInput,
        setPwdInput,
        inputValue,
        setInputValue
    } = useContext(AppContext);

    

    const textSearch = (e) => {
        e.preventDefault();

        if (inputValue === "") {
            window.alert("you typed nothing")
        } else {
            textUpload(inputValue);
        }

        setInputValue("")
    };


    const fileInputRef = useRef(null);

    const openImagePicker = () => {
        fileInputRef.current.click();
    };

    const handelImageSelection = (e) => {
        e.preventDefault()
        const image = e.target.files[0]
        if (!image) {
            alert('Invalid file or you did not choose one!')
            return;
        }

        const fileExtension = image.name.split('.').pop().toLowerCase();

        if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
            alert('Please select .jpg or .png image')
        } else {
            imageUpload(image)
        }
    }

    return (
        <div className={styles.main_div}>
            <div className={styles.input_area}>
                <div className={styles.title}>
                    <h1>Image recognition testing webUI</h1>
                </div>
                <div className={styles.input_div}>
                    <input
                        type="text_input"
                        className={styles.input}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Type in text to search"
                    />
                    <button onClick={textSearch} className={styles.btn} type="submit">Text search</button>
                    <button onClick={openImagePicker} className={styles.btn} type="submit">Image search</button>
                    <input type="file" accept=".jpg,.jpeg,.png" style={{ display: 'none' }} ref={fileInputRef} onChange={handelImageSelection} />
                </div>
            </div>
            <div className={styles.output_area}>
                {isFetching ? <h2 className={styles.indicator}>Fetching...</h2> : (
                    <div>
                        <div className={styles.product_details}>
                            {fetchResult ? <h2>{fetchResult}</h2> : <h2>Type in product name or upload an image</h2>}
                            {productImage && <img src={productImage} alt={fetchResult} className={styles.product_image} />}
                            {manufacturer && <p><strong>Manufacturer:</strong></p>}
                            {manufacturer && <p> {manufacturer}</p>}
                            {composition && <p><strong>Composition:</strong></p>}
                            {composition && <p> {composition}</p>}
                            {description && <p><strong>Description:</strong></p>}
                            {description && <p>{description}</p>}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )

}

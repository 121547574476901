import React from "react";
import styles from './med_card_alt.module.css'


const Med_card_alt = ({
    toggleCanvas,
    each,
    index

}) => {


    return (
        <div onClick={() => toggleCanvas(each)} key={index} className={styles.outer}>

            <img className={styles.img} src={each.med_url}></img>

            <div className={styles.name}>
                <p className={styles.med_p}>{each.med_name}</p>
            </div>

            <div className={styles.description}>
                {
                    each.med_description.length >= 20
                        ?
                        <p className={styles.med_p}>{each.med_description.substring(0, 140) + ' . . .'}</p>
                        :
                        <p className={styles.med_p}>{each.med_description}</p>
                }
            </div>

            <div className={styles.price}>
                <p className={styles.med_p}>
                    {each.med_manufacturer}
                </p>
            </div>

            <div className={styles.position}>
                <p className={styles.med_p}>{each.sto_place_in_store}</p>
            </div>

        </div>
    )
}

export default Med_card_alt;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_pagination__tCTz4 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.pagination_arrow__45KfB {
    padding: 10px 15px;
    font-size: 18px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 5px;
}

.pagination_arrow__45KfB:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination_page_number__mGGPk {
    font-size: 18px;
    margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/stock/pagination.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".pagination {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-top: 20px;\r\n}\r\n\r\n.arrow {\r\n    padding: 10px 15px;\r\n    font-size: 18px;\r\n    border: none;\r\n    background-color: #007bff;\r\n    color: white;\r\n    cursor: pointer;\r\n    border-radius: 5px;\r\n    margin: 0 5px;\r\n}\r\n\r\n.arrow:disabled {\r\n    background-color: #cccccc;\r\n    cursor: not-allowed;\r\n}\r\n\r\n.page_number {\r\n    font-size: 18px;\r\n    margin: 0 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `pagination_pagination__tCTz4`,
	"arrow": `pagination_arrow__45KfB`,
	"page_number": `pagination_page_number__mGGPk`
};
export default ___CSS_LOADER_EXPORT___;

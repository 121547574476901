import React, { useRef, useState, useContext, useEffect } from 'react'
import styles from './image_canvas.module.css'
import { AppContext } from '../../Services/context';

const Image_canvas = ({ isImageCanvasOpen, setIsImageCanvasOpen }) => {


    const { imageUpload } = useContext(AppContext)
    const videoRef = useRef(null);
    const screenShotRef = useRef(null);
    const [currentScreenShot, setCurrentScreenShot] = useState(null);
    const [currentScreenShotURL, setCurrentScreenShotURL] = useState(null);

    const handleTakingPicture = () => {
        const screenShot = screenShotRef.current
        const video = videoRef.current;

        screenShot.width = video.videoWidth;
        screenShot.height = video.videoHeight;

        const context = screenShot.getContext('2d');
        context.drawImage(video, 0, 0, screenShot.width, screenShot.height);

        screenShot.toBlob((blob) => {
            setCurrentScreenShot(blob);
            setCurrentScreenShotURL(URL.createObjectURL(blob))

        }, "image/jpeg");
        stopCamera()
    }

    const handleReTakingPicture = () => {
        setCurrentScreenShotURL(null);
        setCurrentScreenShot(null);
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
                videoRef.current.srcObject = stream;
            })
            .catch(err => {
                console.error("Error accessing the camera: ", err);
                alert('Error accessing camera, plz check that you granted authorisation')
            });
    }

    const handleClose = (e) => {

        e.preventDefault()
        
        setCurrentScreenShotURL(null);
        setCurrentScreenShot(null);
        stopCamera();
        setIsImageCanvasOpen(false);
    };

    const handleImageUpload = (e) => {
        e.preventDefault()

        imageUpload(currentScreenShot)

        setCurrentScreenShot(null);
        setCurrentScreenShotURL(null);


        stopCamera()
        setIsImageCanvasOpen(false);
    }

    const stopCamera = () => {

        const stream = videoRef.current?.srcObject;
        if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    useEffect(() => {
        if (isImageCanvasOpen) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(stream => {
                    videoRef.current.srcObject = stream;
                })
                .catch(err => {
                    console.error("Error accessing the camera: ", err);
                    alert('Error accessing camera, plz check that you granted authorisation')
                });
        }
    }, [isImageCanvasOpen]);


    return (
        <>
            {isImageCanvasOpen && (

                <div className={styles.overlay}>

                    <canvas ref={screenShotRef} style={{ display: 'none' }}></canvas>

                    <div className={styles.floating_window}>

                        <div className={styles.cameraDiv}>

                            {currentScreenShot
                                ?
                                <img className={styles.camera} src={currentScreenShotURL}></img>
                                :
                                <video ref={videoRef} autoPlay className={styles.camera}></video>
                            }

                        </div>
                        <div className={styles.btnDiv}>

                            <button className={styles.btns} onClick={handleClose}>
                                close
                            </button>

                            {currentScreenShot
                                ?
                                <button className={styles.btns} onClick={handleImageUpload}>
                                    Upload
                                </button>
                                :
                                <button className={styles.btns} onClick={handleTakingPicture}>
                                    Take
                                </button>
                            }

                            <button className={`${styles.btns} ${currentScreenShot ? null : styles.disabled}`} onClick={handleReTakingPicture} disabled={currentScreenShot ? false : true}>
                                Re-take
                            </button>
                        </div>

                    </div>


                </div>
            )}
        </>



    )

}


export default Image_canvas;

import React from "react";
import styles from './med_card.module.css'


const Med_card = ({
    toggleCanvas,
    each,
    index

}) => {


    return (
        <div onClick={() => toggleCanvas(each)} key={index} className={styles.outer}>

            <img className={`${styles.img} ${each.sto_quan == 0 ? styles.out_of_stock : null} `} src={each.med_url}></img>

            <div className={styles.name}>
                <p className={styles.med_p}>{each.med_name}</p>
            </div>

            <div className={styles.description}>
                {
                    each.med_description.length >= 20
                        ?
                        <p className={styles.med_p}>{each.med_description.substring(0, 140) + ' . . .'}</p>
                        :
                        <p className={styles.med_p}>{each.med_description}</p>
                }
            </div>

            <div className={styles.price}>
                <p className={styles.med_p}>
                    {each.sto_quan == 0 ? 'Out of stock' : `$${each.sto_pri} NZD`}
                </p>
            </div>

            <div className={styles.position}>
                <p className={styles.med_p}>{each.sto_place_in_store}</p>
            </div>

        </div>
    )
}

export default Med_card;
import styles from './pwdinput.module.css'
import React from 'react'
import { useTranslation } from 'react-i18next'


const Pwdinput = ({
    isPwdinputOpen,
    setIsPwdinputOpen,
    finishEdit,
    pwdValue,
    setPwdvalue,
    msg,
    setMsg,
    setIsEditing
}) => {

    const {t} = useTranslation()

    const handleCancel = (e) => {
        e.preventDefault();
        setMsg('');
        setPwdvalue('');
        setIsPwdinputOpen(false);
        setIsEditing(false);

    }

    return (<>
        {isPwdinputOpen
            &&
            <div className={styles.blocker}>
                <div className={styles.outer}>
                    <h4>{t('stock_pin_title')}</h4>

                    <input value={pwdValue} onChange={(e) => setPwdvalue(e.target.value)} className={styles.inpt}></input>

                    {msg === '' ? null : <p className={styles.msg}>{msg}</p>}
                    <button onClick={(e) => finishEdit(e)} disabled={pwdValue === '' ? true : false} className={`${styles.btnn} ${pwdValue === '' ? styles.dis : null}`}>
                        {t('stock_pin_verify')}
                    </button>

                    <button onClick={(e) => handleCancel(e)} className={styles.btnn}>
                        {t('stock_pin_cancel')}
                    </button>

                </div>
            </div>
        }
    </>)

}

export default Pwdinput;